import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import LinkWithTooltip from '../components/linkwithtooltip.component';
import { observer } from 'mobx-react-lite';
import { ActivePageStoreContext, PageIndex } from '../../../stores/activepage.store';
import { WebSocketStoreContext } from '../../../stores/websocket.store';
import { ZonesDataStoreContext } from '../../../stores/zonesdata.store';
import { InhibitorsStoreContext } from '../../../stores/inhibitors.store';
import { DetectorsStoreContext } from '../../../stores/detectors.store';
import { UserDataStoreContext } from '../../../stores/userdata.store';
import { GroupDataStoreContext } from '../../../stores/groupdata.store';
import { RegistersDataStoreContext, } from '../../../stores/registersdata.store';
import ProgilePage from '../../profile/page/profilepage';
import ZonesPage from '../../zones/page/zonespage';
import DronesPage from '../../drones/page/dronespage';
import jwt_decode from 'jwt-decode';
import TestPage from '../../TEST/testpage';
import RegistersColumn from '../../registers/components/registerscolumn.component';
import RealtimeColumn from '../../realtime/components/realtimecolumn.component';
import InhibitorComp from '../../TEST/InhibitorComp';
import DirectionsComp from '../../TEST/DirectionsComp';
import DetectorsComp from '../../TEST/DetectorsComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faGlobe } from '@fortawesome/free-solid-svg-icons'
import AlertPopup from '../components/AlertPopup';
import UsersManagementPage from '../../usersManagement/page/usersManagementPage';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select"
import ministerio from '../../../images/loginLogos/ministerio.png';
import laliga from '../../../images/loginLogos/laliga.png';
import telefonica from '../../../images/loginLogos/new_telefonica.png';
import cumbre from '../../../images/loginLogos/Cumbre.png';
import siglo from '../../../images/loginLogos/SIGLO.png';
import asdt from '../../../images/loginLogos/ASDT_blue.png';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const { Option } = components;
const IconOption = (props: any) => (
    <Option {...props}>
        <img
            src={require("../../../images/flags/" + props.data.icon)}
            style={{ width: 20, marginRight: "5px" }}
            alt={props.data.label}
        />
        {props.data.label}
    </Option>
);

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={faGlobe} />
        </components.DropdownIndicator>
    );
};

const customSelectStyles = {
    singleValue: (provided: any, state: any) => {
        const display = "none";
        return { ...provided, display };
    },
    input: (provided: any, state: any) => {
        const display = "none";
        return { ...provided, display };
    },
    valueContainer: (provided: any, state: any) => {
        const display = "none";
        return { ...provided, display };
    },
    control: (provided: any, state: any) => {
        const width = "fit-content";
        const border = "none";
        const backgroundColor = "transparent";
        const cursor = "pointer";
        return { ...provided, width, border, backgroundColor, cursor };
    },
    indicatorSeparator: (provided: any, state: any) => {
        const display = "none";
        return { ...provided, display };
    },
    option: (provided: any, state: any) => {
        const width = "150px";
        return { ...provided, width };
    },
    menu: (provided: any, state: any) => {
        const width = "150px";
        const right = "0";
        return { ...provided, width, right };
    },
    menuList: (provided: any, state: any) => {
        const width = "150px";
        return { ...provided, width };
    }
}


const HomePage = observer(() => {

    const [t, i18n] = useTranslation("common");

    const activepageStore = useContext(ActivePageStoreContext);
    const realtimeStore = useContext(WebSocketStoreContext);
    const websocketStore = useContext(WebSocketStoreContext);
    const zonesDataStore = useContext(ZonesDataStoreContext);
    const inhibitorsdataStore = useContext(InhibitorsStoreContext);
    const detectorsdataStore = useContext(DetectorsStoreContext);
    const userdataStore = useContext(UserDataStoreContext);
    const groupdataStore = useContext(GroupDataStoreContext);
    const registersdataStore = useContext(RegistersDataStoreContext);
    const [downloadingDoc, setDownloadingDoc] = useState<boolean>(false);

    useEffect(() => {
        let token = localStorage.getItem('userToken');
        if (!token) window.location.reload();

        websocketStore.connect(token!);
        zonesDataStore.getZones();
        groupdataStore.getGroup();
        userdataStore.getUser().then(() => {
            if (userdataStore.status === 3) {
                if (userdataStore.user) inhibitorsdataStore.getGroupInhibitors(userdataStore.user.group);
                if (userdataStore.user) detectorsdataStore.getGroupDetectors(userdataStore.user.group);
            }
        }
        );

        setInterval(() => {
            // Check every 5 seconds JWT validity
            const token = localStorage.getItem("userToken")
            if (!token) window.location.reload();

            const decodedToken: any = jwt_decode(token!);
            const exp = decodedToken.exp

            const isExpired = Date.now() >= exp * 1000

            if (isExpired) {
                localStorage.removeItem("userToken");
                window.location.reload();
            }

            if (userdataStore.user) realtimeStore.updateInhibitors = true
        }, 5000);
    }, []);

    useEffect(() => {
        registersdataStore.activeController = [];
        registersdataStore.activeRegisters = [];
    }, [activepageStore.activePage])

    async function updateInhibitors() {
        await inhibitorsdataStore.getGroupInhibitors(userdataStore.user.group);
        
    }
    if (websocketStore.updateInhibitors) {
        updateInhibitors();
    }


    async function updateDetectors() {
        await detectorsdataStore.getGroupDetectors(userdataStore.user.group);
    }
    if (websocketStore.updateDetectors) {
        updateDetectors();
    }

    let mapLayers = localStorage.getItem("mapLayers") || "0000";

    const getActivePage = () => {
        switch (activepageStore.activePage) {
            case PageIndex.Registros:
                return <TestPage
                    key={0}
                    child={<RegistersColumn
                        onDownloadStarted={() => setDownloadingDoc(true)}
                        onDownloadEnded={() => setDownloadingDoc(false)}
                    />}
                    type="registers"
                    showInhibitors
                    showDetectors
                    showZones
                    defaultSwitchValues={[mapLayers[0] == "1", mapLayers[1] == "1", mapLayers[2] == "1", mapLayers[3] == "1"]}
                    showAudioToggle
                    secondChild={null}
                    thirdChild={null}
                    fourthChild={<DetectorsComp />}
                    showDownloadingDoc={downloadingDoc}
                />
            case PageIndex.Realtime:
                return <TestPage
                    key={1}
                    child={<RealtimeColumn />}
                    type="realtime"
                    showInhibitors
                    showDetectors
                    showZones
                    showFlights
                    defaultSwitchValues={[mapLayers[0] == "1", mapLayers[1] == "1", mapLayers[2] == "1", mapLayers[3] == "1"]}
                    showAudioToggle
                    secondChild={<InhibitorComp />}
                    thirdChild={<DirectionsComp />}
                    fourthChild={<DetectorsComp />}
                />
            case PageIndex.Zonas:
                return <ZonesPage />
            case PageIndex.Drones:
                return <DronesPage />
            case PageIndex.Gestión:
                return <UsersManagementPage />
            case PageIndex.Perfil:
                if (userdataStore.status == 3) {
                    return <ProgilePage />
                }
        }
    }


    const languageOptions = [
        { value: "es", label: t("navbar.language-es"), icon: "spanish.svg" },
        { value: "en", label: t("navbar.language-en"), icon: "english.svg" },
        { value: "ca", label: t("navbar.language-ca"), icon: "catalan.svg" },
        { value: "pl", label: t("navbar.language-pl"), icon: "polish.svg" },
    ]

    const GROUPS_MINISTERIO_IMAGE = ["Administradores Centrales SIGLO", "CNP", "Casa Real", "CNI", "AEAT", "Guardia Civil", "Policía Nacional", "Presidencia del Gobierno", "Centros Penitenciarios", "Cumbre"]

    return (
        <>
            {websocketStore.websocket && websocketStore.alertPopupMessage && !websocketStore.isWebsocketConnected ?
                <div style={{
                    width: "100vw", height: "100vh",
                    backgroundColor: "rgba(0,0,0,0.3)", zIndex: 1001, position: "absolute",
                    backdropFilter: "blur(5px)", display: "flex", alignItems: "center", justifyContent: "center"
                }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <AlertPopup
                        message={websocketStore.alertPopupMessage}
                        buttonTitle="Aceptar y salir"
                        action={() => {
                            localStorage.removeItem('userToken')
                            window.location.reload()
                        }}
                    />
                </div>
                :
                null}
            {websocketStore.couldNotConnect && websocketStore.alertPopupMessage == "" ?
                <div style={{
                    width: "100vw", height: "100vh",
                    backgroundColor: "rgba(0,0,0,0.3)", zIndex: 1001, position: "absolute",
                    backdropFilter: "blur(5px)", display: "flex", alignItems: "center", justifyContent: "center"
                }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <AlertPopup
                        message="Se ha perdido la conexión con el servidor"
                        buttonTitle="Aceptar y salir"
                        action={() => {
                            localStorage.removeItem('userToken')
                            window.location.reload()
                        }}
                    />
                </div>
                :
                null}
            <div style={{ height: "100vh", overflow: "hidden" }}>
                <Navbar collapseOnSelect expand="lg" bg="light">
                    <Navbar.Brand>
                        {
                            groupdataStore.group ?
                                <img
                                    onClick={async () => await activepageStore.changeActivePage(PageIndex.Registros)}
                                    src={'api/img/logos/' + groupdataStore.group.logo + '.png'}
                                    //src={require('../../../images/logotipo_siglo.png')}
                                    width="160"
                                    height="55"
                                    alt="logo"
                                    style={{ cursor: "pointer", objectFit: "contain", maxWidth: "160px", minWidth: "160px", minHeight: "55px", maxHeight: "55px", justifyContent: "center" }}
                                />
                                :
                                null
                        }
                        {
                            groupdataStore.group && groupdataStore.group.name === "Cumbre" ?
                                <img
                                    onClick={async () => await activepageStore.changeActivePage(PageIndex.Registros)}
                                    src={cumbre}
                                    //src={require('../../../images/logotipo_siglo.png')}
                                    width="190px"
                                    height="32.5px"
                                    alt="logo"
                                    style={{ margin: "0 1rem 0 2rem", cursor: "pointer", justifyContent: "center" }}
                                />
                                :
                                null
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav style={{ alignItems: "center" }} className="all-width">
                            <LinkWithTooltip
                                name={t("navbar.detections")}
                                text={t("navbar.detections-hover")}
                                value={PageIndex.Registros}
                            />
                            <LinkWithTooltip name={<span style={{ color: Object.keys(websocketStore.activeDetections).length > 0 ? "red" : undefined, animation: websocketStore.activeDetections.length > 0 ? "glow 1s ease-in-out infinite alternate" : undefined }}>{t("navbar.realtime")} {Object.keys(websocketStore.activeDetections).length > 0 ? <FontAwesomeIcon icon={faExclamationCircle} /> : null}</span>}
                                text={t("navbar.realtime-hover")}
                                value={PageIndex.Realtime}
                            />
                            {
                                userdataStore.user ?
                                    userdataStore.user.role !== "USER" ?
                                        <>
                                            <LinkWithTooltip name={t("navbar.zones")}
                                                text={t("navbar.zones-hover")}
                                                value={PageIndex.Zonas}
                                            />
                                            <LinkWithTooltip name={t("navbar.drones")}
                                                text={t("navbar.drones-hover")}
                                                value={PageIndex.Drones}
                                            />
                                            {
                                                userdataStore.user.group == "5f05780ab902bc3a58e303bb" || userdataStore.user["_id"] == "6193a183d790fa9b3cb7068b" || userdataStore.user["_id"] == "61968ef13b7894ec9b3ae1d9" || userdataStore.user["_id"] == "61968e693b7894ec9b3ae1c1" ?// || userdataStore.user["email"] == "mic@interior.es" || userdataStore.user["email"] == "xavier.rius@gencat.cat" ?
                                                    userdataStore.user.role !== "USER" ?
                                                        <LinkWithTooltip name={t("navbar.management")}
                                                            text={t("navbar.management-hover")}
                                                            value={PageIndex.Gestión}
                                                        />
                                                        : null
                                                    : null
                                            }
                                        </>
                                        :
                                        null
                                    :
                                    null
                            }
                            <div style={{ alignItems: "center" }} className="push-right">
                                {
                                    groupdataStore.group && groupdataStore.group.name === "Cumbre" ?
                                        <img
                                            onClick={async () => await activepageStore.changeActivePage(PageIndex.Registros)}
                                            src={siglo}
                                            //src={require('../../../images/logotipo_siglo.png')}
                                            width="150px"
                                            height="55px"
                                            alt="logo"
                                            style={{ margin: "0 1rem 0 2rem", cursor: "pointer", justifyContent: "center" }}
                                        />
                                        :
                                        null
                                }

                                {
                                    GROUPS_MINISTERIO_IMAGE.includes(groupdataStore.group?.name) ?
                                        <img
                                            className="push-right"
                                            width="160"
                                            height="auto"
                                            style={{ position: "relative", maxWidth: "160px", minWidth: "160px", minHeight: "55px", maxHeight: "55px", justifyContent: "center" }}
                                            src={ministerio} alt="logo-ministerio"></img> : (
                                            groupdataStore.group?._id === "63f3975a4344526484f31370" ? (<>
                                                <img
                                                    className="push-right"
                                                    width="160"
                                                    height="auto"
                                                    style={{ position: "relative", maxWidth: "160px", minWidth: "160px", minHeight: "67px", maxHeight: "67px", justifyContent: "center", marginRight: "1rem" }}
                                                    src={telefonica} alt="logo-telefonica">
                                                </img>
                                            </>)
                                                : null
                                        )}
                                <LinkWithTooltip name={t("navbar.profile")}
                                    text={t("navbar.profile-hover")}
                                    value={PageIndex.Perfil}
                                />
                                <OverlayTrigger
                                    key={"navbar_language"}
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='centerLocation'>
                                            {t("navbar.btn-language-hover")}
                                        </Tooltip>
                                    }>
                                    <div style={{ zIndex: 2000 }}>
                                        <Select
                                            defaultValue={localStorage.getItem('language') ? languageOptions.find((language) => language.value === localStorage.getItem('language')) : languageOptions[1]}
                                            options={languageOptions}
                                            components={{ Option: IconOption, DropdownIndicator }}
                                            onChange={(e) => { i18n.changeLanguage(e?.value); localStorage.setItem('language', e?.value || "es") }}
                                            styles={customSelectStyles}
                                        />
                                    </div>
                                </OverlayTrigger>
                                <img
                                    onClick={async () => await activepageStore.changeActivePage(PageIndex.Realtime)}
                                    src={require('../../../images/byASDT_logo.png')}
                                    width="100"
                                    height="30"
                                    style={{ marginLeft: "10px" }}
                                    alt="logotipo de ASDT"
                                />
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <a
                        target="_blank"
                        href="http://www.asdt.eu/"
                    >

                    </a>
                </Navbar>
                <div className="all-app-remaining-height" style={{ height: "100%" }}>
                    {getActivePage()}
                </div>
            </div>
        </>
    );
});

export default HomePage;