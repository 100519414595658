import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';
import { WebSocketStoreContext } from '../../../stores/websocket.store';
import { MapAnimationsStoreContext, FocusStatus } from '../../../stores/mapanimations.store';
import { DetectorsStoreContext } from '../../../stores/detectors.store';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { updateDrone } from '../../../api/index';
import { UserDataStoreContext } from '../../../stores/userdata.store';
import { useTranslation } from 'react-i18next';

const RealtimeColumn = observer(() => {

    const [t, i18n] = useTranslation("common");

    const websocketStore = useContext(WebSocketStoreContext);
    const mapanimationsStore = useContext(MapAnimationsStoreContext);
    const detectorsStore = useContext(DetectorsStoreContext);
    const userStore = useContext(UserDataStoreContext);

    function isAllGPSOff(detectionLog: any): boolean {
        var isOff: boolean = true;
        var i = 0;
        while (isOff && (i < detectionLog[1].length - 1)) {
            if (detectionLog[1][i].droneLocation.lat != 0 || detectionLog[1][i].droneLocation.lon != 0) isOff = false;
            i++;
        }
        return isOff;
    }

    function calcVel(vx: number, vy: number) {
        return Math.sqrt(Math.pow(vx, 2) + Math.pow(vy, 2)).toFixed(2);
    }

    function calcAng(vx: number, vy: number) {
        var res = "";
        var ang;
        if (vy > 0) {
            res += "N ";
            if (vx = 0) {
                res += "0º"
            }
            if (vx > 0) {
                ang = Math.atan(vy / vx);
                res += (90 - ang).toString() + "º E"
            }
            if (vx < 0) {
                ang = 180 + Math.atan(vy / vx);
                res += (ang - 90).toString() + "º W"
            }
        }
        if (vy < 0) {
            res += "S ";
            if (vx = 0) {
                res += "0º"
            }
            if (vx < 0) {
                ang = 180 + Math.atan(vy / vx);
                res += (270 - ang).toString() + "º W"
            }
            if (vx > 0) {
                ang = Math.atan(vy / vx);
                res += (ang - 270).toString() + "º E"
            }
        }
        return res
    }

    function getLastYaw(detectionLog: any) {
        let lastDetectionTime = new Date(detectionLog[detectionLog.length - 1].time);
        
        for (let i = detectionLog.length - 1; i >= 0; i--) {
          const detection = detectionLog[i];
    
          // Check if the detection has ocurred in the last 5 seconds
          let detectionDate = new Date(detection.time);
    
          if (lastDetectionTime.getTime() - detectionDate.getTime() > 5000) {
            break;
          }
    
          if (detection.yaw != null) return parseFloat(detection.yaw);
        }
    
        return null;
    }

    function getCards(): any {
        var aux = [];

        for (var i = 0; i < Object.keys(websocketStore.activeDetections).length; i++) {
            let idx = Object.keys(websocketStore.activeDetections)[i];
            let detectionLog = websocketStore.activeDetections[idx];

            
            let lastYaw = getLastYaw(detectionLog[1]);

            let color = "orange"
            
            if (detectionLog?.[1]?.[detectionLog[1].length - 1]?.drone?.type) {
                switch (detectionLog[1][detectionLog[1].length - 1].drone.type) {
                    case "FRIEND":
                    case "AUTHORIZED":
                        color = "green"
                        break;
    
                    case "STOLEN":
                        color = "chocolate"
                        break;
                    
                    case "FAKE":
                    case "NEUTRALIZED":
                        color = "black";
                        break;
                    
                    case "ENEMY":
                        color = "red";
                        break;
                    
                    case "NOTIFY":
                        color = "blue";
                        break;

    
                    default:
                        color = "orange";
                }

            }
            
            var dateIni = new Date(detectionLog[1][detectionLog[1].length - 1].dateIni).toLocaleString();
            var dateFin = new Date(detectionLog[1][detectionLog[1].length - 1].dateFin).toLocaleString();
            let modelImage = detectionLog[1][detectionLog[1].length - 1].drone.model ? detectionLog[1][detectionLog[1].length - 1].drone.model.image : "unknown-drone.png";
            aux.push(
                <Card style={{ margin: "10px" }} key={"det_" + detectionLog[1][0].dateIni.toString + "_" + detectionLog[1][0].drone.sn || detectionLog[1][0].drone.mac}>
                    <Card.Header style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ backgroundColor: color, width: "5px", height: "30px", marginRight: "1rem" }} />
                        <img src={'/api/img/models/' + modelImage} style={{ width: "90px", height: "90px" }}></img>
                        <Accordion.Toggle as={Button} variant="link" eventKey={detectionLog[1][0].drone.sn || detectionLog[1][0].drone.mac} style={{ width: "-webkit-fill-available" }}>
                            {dateIni} <br /> {detectionLog[1][detectionLog[1].length - 1].drone.sn ? detectionLog[1][detectionLog[1].length - 1].drone.sn : detectionLog[1][detectionLog[1].length - 1].drone.mac ? detectionLog[1][detectionLog[1].length - 1].drone.mac : detectionLog[1][detectionLog[1].length - 1]?.drone?.model?.brand && detectionLog[1][detectionLog[1].length - 1]?.communicationProtocol  ? `${detectionLog[1][detectionLog[1].length - 1].drone.model.brand.toString()} / ${detectionLog[1][detectionLog[1].length - 1].communicationProtocol.toString()}` : ""}
                        </Accordion.Toggle>
                        <Button
                            style={{ backgroundColor: isAllGPSOff(detectionLog) ? 'grey' : '' }}
                            onClick={() => { mapanimationsStore.focusStatus === FocusStatus.NotFocused ? mapanimationsStore.focusOnDetection(detectionLog, detectorsStore.detectors) : (
                                
                                (()=>{

                                    console.log("focusOnDetection not triggered");
                                    
                                    mapanimationsStore.focusStatus = FocusStatus.NotFocused

                                })()
                                ) }}>
                            <FontAwesomeIcon icon={faLocationArrow} />
                        </Button>
                    </Card.Header>
                    <Accordion.Collapse eventKey={detectionLog[1][0].drone.sn || detectionLog[1][0].drone.mac}>
                        <Card.Body style={{ padding: "0rem 1.25rem" }}>
                            {
                                detectionLog[1][detectionLog[1].length - 1].drone.type === "FAKE" ?
                                    <div style={{ backgroundColor: "yellow", marginTop: "10px", padding: "10px", borderRadius: "7px" }}>
                                        <span>El sistema considera que esta detección podría ser fraudulenta</span>
                                    </div>
                                    :
                                    null
                            }
                            <div style={{ margin: "15px", display: "flex", justifyContent: "center" }}>
                                {
                                    userStore.user.role !== "USER" ?
                                        <div style={{display: "flex", flexDirection: "column", gap:4}} >
                                        <ButtonGroup style={{gap:4}}>
                                            <OverlayTrigger
                                                key={i.toString() + "FRIEND"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "FRIEND"}`}>
                                                        {t("pages.realtime.btn-friend-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "FRIEND")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "FRIEND" ? "green" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "FRIEND" ? "green" : "grey" }}>{t("pages.realtime.btn-friend-drone")}</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={i.toString() + "NEUTRAL"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "NEUTRAL"}`}>
                                                        {t("pages.realtime.btn-neutral-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "NEUTRAL")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "NEUTRAL" ? "orange" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "NEUTRAL" ? "orange" : "grey" }}>{t("pages.realtime.btn-neutral-drone")}</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={i.toString() + "ENEMY"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "ENEMY"}`}>
                                                        {t("pages.realtime.btn-enemy-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "ENEMY")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "ENEMY" ? "red" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "ENEMY" ? "red" : "grey" }}>{t("pages.realtime.btn-enemy-drone")}</Button>
                                            </OverlayTrigger>
                                            
                                        </ButtonGroup>
                                        <ButtonGroup  style={{gap:4}}>
                                            
                                            <OverlayTrigger
                                                key={i.toString() + "NEUTRALIZED"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "NEUTRALIZED"}`}>
                                                        {t("pages.realtime.btn-neutralized-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button 
                                                    onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "NEUTRALIZED")} 
                                                    className={"optionButton"} 
                                                    style={{ 
                                                        backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "NEUTRALIZED" ? "black" : "grey", 
                                                        borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "NEUTRALIZED" ? "black" : "grey" 
                                                    }}
                                                >
                                                    {t("pages.realtime.btn-neutralized-drone")}
                                                </Button>
                                            </OverlayTrigger>

                                            {/* Button for NOTIFY */}
                                            <OverlayTrigger
                                                key={i.toString() + "NOTIFY"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "NOTIFY"}`}>
                                                        {t("pages.realtime.btn-notify-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button 
                                                    onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "NOTIFY")} 
                                                    className={"optionButton"} 
                                                    style={{ 
                                                        backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "NOTIFY" ? "blue" : "grey", 
                                                        borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "NOTIFY" ? "blue" : "grey" 
                                                    }}
                                                >
                                                    {t("pages.realtime.btn-notify-drone")}
                                                </Button>
                                            </OverlayTrigger>

                                            {/* Button for AUTHORIZED */}
                                            <OverlayTrigger
                                                key={i.toString() + "AUTHORIZED"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "AUTHORIZED"}`}>
                                                        {t("pages.realtime.btn-authorized-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button 
                                                    onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "AUTHORIZED")} 
                                                    className={"optionButton"} 
                                                    style={{ 
                                                        backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "AUTHORIZED" ? "green" : "grey", 
                                                        borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "AUTHORIZED" ? "green" : "grey" 
                                                    }}
                                                >
                                                    {t("pages.realtime.btn-authorized-drone")}
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                        <ButtonGroup  style={{gap:4}}>
                                            
                                            <OverlayTrigger
                                                key={i.toString() + "STOLEN"}
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "stolen"}`}>
                                                        {t("pages.realtime.btn-stolen-drone-hover")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button 
                                                    onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "STOLEN")} 
                                                    className={"optionButton"} 
                                                    style={{ 
                                                        backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "STOLEN" ? "chocolate" : "grey", 
                                                        borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type === "STOLEN" ? "chocolate" : "grey" 
                                                    }}
                                                >
                                                    {t("pages.realtime.btn-stolen-drone")}
                                                </Button>
                                            </OverlayTrigger>

                                        </ButtonGroup>
                                        
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <Table responsive>
                                <tbody>

                                    {/* 1. MANUFACTURER */}
                                    <tr>
                                        <td style={{ fontWeight: "bold", borderTop: "none", width: "50%" }}>{t("drone.brand")}</td>
                                        <td style={{ borderTop: "none" }}>{
                                            detectionLog[1][detectionLog[1].length - 1].drone.model ?
                                                String(detectionLog[1][detectionLog[1].length - 1].drone.model.brand)
                                                :
                                                String(t("drone.brand-unknown"))
                                        }</td>
                                    </tr>

                                    {/* 2. MODEL */}
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>{t("drone.model")}</td>
                                        <td style={{ verticalAlign: "middle" }}>{
                                            detectionLog[1][detectionLog[1].length - 1].drone.model ?
                                                String(detectionLog[1][detectionLog[1].length - 1].drone.model.model)
                                                :
                                                String(t("drone.model-unknown"))
                                        }</td>
                                    </tr>

                                    {/* 3. WEIGHT */}
                                    {detectionLog[1][detectionLog[1].length - 1].drone.model ?
                                        detectionLog[1][detectionLog[1].length - 1].drone.model.weight ?
                                            <tr>

                                                <td style={{ fontWeight: "bold" }}>{t("drone.weight")}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    {String(detectionLog[1][detectionLog[1].length - 1].drone.model.weight)}
                                                </td>
                                            </tr>
                                            :
                                            null
                                        :
                                        null
                                    }

                                    {/* 4. SERIAL NUMBER */} 
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].drone.sn ? (
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("drone.identifier")}</td>
                                                <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].drone.sn}</td>
                                            </tr>
                                        ) : null
                                    }

                                    {/* 4.1 REMOTE ID SERIAL NUMBER */} 
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].drone.uid ? (
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("drone.rIdentifier")}</td>
                                                <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].drone.uid}</td>
                                            </tr>
                                        ) : null
                                    }

                                    {/* 5. MAC */}
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].drone.mac ?
                                        (
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("drone.mac")}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    { detectionLog[1][detectionLog[1].length - 1].drone.mac }
                                                </td>
                                            </tr>
                                        ) : null
                                    }

                                    {/* 6. PROTOCOL */}
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].communicationProtocol ?
                                        (
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("drone.vendor")}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    { 
                                                        Array.isArray(detectionLog[1][detectionLog[1].length - 1].communicationProtocol) ? detectionLog[1][detectionLog[1].length - 1].communicationProtocol.join(", ") : detectionLog[1][detectionLog[1].length - 1].communicationProtocol
                                                    }
                                                </td>
                                            </tr>
                                        ) : null
                                    }

                                    {/* 7. OPERATOR */}
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].operatorId ?
                                            <tr>
                                                <td style={{ fontWeight: "bold",  width: "50%" }}>{t("detection.operator")}</td>
                                                <td style={{ }}>{
                                                    String(detectionLog[1][detectionLog[1].length - 1].operatorId)
                                                }</td>
                                            </tr>
                                            :
                                            null
                                    }

                                    {/* 8. MOTOR */}
                                    {
                                        // detectionLog[1][detectionLog[1].length - 1].motor ?
                                        //     <tr>
                                        //         <td style={{ fontWeight: "bold",  width: "50%" }}>{t("detection.motor")}</td>
                                        //         <td style={{ }}>{
                                        //             t("detection.motorOn")
                                        //         }</td>
                                        //     </tr>
                                        //     :
                                        //     null
                                    }

                                    {/* 9. YAW */}
                                    {
                                        detectionLog[1][detectionLog[1].length - 1].signalType &&
                                        Array.isArray(detectionLog[1][detectionLog[1].length - 1].signalType) && 
                                        detectionLog[1][detectionLog[1].length - 1].signalType.length > 1 
                                            ?
                                            <tr>
                                                <td style={{ fontWeight: "bold",  width: "50%" }}>{t("detection.yaw")}</td>
                                                <td>{String(lastYaw || "-")}</td>
                                            </tr>
                                            : (lastYaw ? (
                                                <tr>
                                                    <td style={{ fontWeight: "bold",  width: "50%" }}>{t("detection.yaw")}</td>
                                                    <td>{String(lastYaw || "-")}</td>
                                                </tr>
                                                ) : null
                                            )
                                    }

                                    {
                                        detectionLog[1][detectionLog[1].length - 1].drone.owner ?
                                            <tr>
                                                <td style={{ fontWeight: "bold", borderTop: "none", width: "50%" }}>{t("detection.authorized-by")}</td>
                                                <td style={{ borderTop: "none" }}>{
                                                    String(detectionLog[1][detectionLog[1].length - 1].drone.owner)
                                                }</td>
                                            </tr>
                                            :
                                            null
                                    }
                                    
                                    {
                                        /*!(detectionLog[1][detectionLog[1].length - 1].drone.type === "FAKE") ?
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>Relación del dron</td>
                                                <td>{detectionLog[1][detectionLog[1].length - 1].drone.type}</td>
                                            </tr> :
                                            null */
                                    }
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>{t("detection.last-point")}</td>
                                        <td style={{ verticalAlign: "middle" }}>{dateFin}</td>
                                    </tr>

                                    
                                    
                                    {/* <tr>
                                    <td style={{fontWeight: "bold"}}>Tiempo de vuelo</td>
                                    <td>{Math.abs(new Date(detectionLog[1][detectionLog[1].length-1].droneLocation.dateIni) - new Date(detectionLog[1][detectionLog[1].length-1].dateFin))/60.toFixedString(2)}min</td>
                                </tr> */}
                                    {
                                        !isAllGPSOff(detectionLog) ?
                                            <>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.points")}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{detectionLog[1].length.toString()}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.last-drone-location")}</td>
                                                    {
                                                        detectionLog[1][detectionLog[1].length - 1].droneLocation ?
                                                            <td style={{ verticalAlign: "middle" }}>({detectionLog[1][detectionLog[1].length - 1].droneLocation.lat ? detectionLog[1][detectionLog[1].length - 1].droneLocation.lat.toFixed(4) : ""}, {detectionLog[1][detectionLog[1].length - 1].droneLocation.lon ? detectionLog[1][detectionLog[1].length - 1].droneLocation.lon.toFixed(4) : ""})</td>
                                                            :
                                                            <td style={{ verticalAlign: "middle" }}>(-)</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.last-pilot-location")}</td>
                                                    {
                                                        detectionLog[1][detectionLog[1].length - 1].driverLocation ?
                                                            <td style={{ verticalAlign: "middle" }}>({detectionLog[1][detectionLog[1].length - 1].driverLocation.lat ? detectionLog[1][detectionLog[1].length - 1].driverLocation.lat.toFixed(4) : ""}, {detectionLog[1][detectionLog[1].length - 1].driverLocation.lon ? detectionLog[1][detectionLog[1].length - 1].driverLocation.lon.toFixed(4) : ""})</td>
                                                            :
                                                            <td style={{ verticalAlign: "middle" }}>(-)</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.last-home-location")}</td>
                                                    {
                                                        detectionLog[1][detectionLog[1].length - 1].homeLocation ?
                                                            <td style={{ verticalAlign: "middle" }}>({detectionLog[1][detectionLog[1].length - 1].homeLocation.lat ? detectionLog[1][detectionLog[1].length - 1].homeLocation.lat.toFixed(4) : ""}, {detectionLog[1][detectionLog[1].length - 1].homeLocation.lon ? detectionLog[1][detectionLog[1].length - 1].homeLocation.lon.toFixed(4) : ""})</td>
                                                            :
                                                            <td style={{ verticalAlign: "middle" }}>(-)</td>
                                                    }
                                                </tr>
                                                {
                                                    detectionLog[1][detectionLog[1].length - 1].distanceToDetector ?
                                                        <tr>
                                                            <td style={{ fontWeight: "bold" }}>{t("detection.distance")}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].distanceToDetector.toFixed(0)} m</td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    /*(detectionLog[1][detectionLog[1].length - 1].vx > 0 || detectionLog[1][detectionLog[1].length - 1].vy > 0) ?
                                                        <tr>
                                                            <td style={{ fontWeight: "bold" }}>Velocidad dron</td>
                                                            <td style={{ verticalAlign: "middle" }}>{calcVel(detectionLog[1][detectionLog[1].length - 1].vx, detectionLog[1][detectionLog[1].length - 1].vy)} m/s</td>
                                                        </tr>
                                                        : null*/
                                                }
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.velocity")}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{(detectionLog[1][detectionLog[1].length - 1].vx > 0 || detectionLog[1][detectionLog[1].length - 1].vy > 0) ? calcVel(detectionLog[1][detectionLog[1].length - 1].vx, detectionLog[1][detectionLog[1].length - 1].vy) : "0"} m/s</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.height")}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].fHeight} m</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.min-height")}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].minHeight} m</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>{t("detection.max-height")}</td>
                                                    <td style={{ verticalAlign: "middle" }}>{detectionLog[1][detectionLog[1].length - 1].maxHeight} m</td>
                                                </tr>
                                            </> :
                                            null
                                    }

                                    {
                                        detectionLog[1][detectionLog[1].length - 1].drone.model && detectionLog[1][detectionLog[1].length - 1].drone.model.observations ?
                                            <tr>
                                                <td style={{ fontWeight: "bold", borderTop: "none", width: "50%" }}>{t("detection.comments")}</td>
                                                <td style={{ borderTop: "none" }}>{String(detectionLog[1][detectionLog[1].length - 1].drone.model.observations)}</td>
                                            </tr> : null
                                    }
                                </tbody>
                            </Table>
                            {/*<div style={{ margin: "15px", display: "flex", justifyContent: "center" }}>
                                {
                                    userStore.user.role !== "USER" ?
                                        <ButtonGroup>
                                            <OverlayTrigger
                                                key={i.toString() + "FRIEND"}
                                                placement={"right"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "FRIEND"}`}>
                                                        Marcar dron como amigo
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "FRIEND")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "FRIEND" ? "green" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "FRIEND" ? "green" : "grey" }}>Amigo</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={i.toString() + "NEUTRAL"}
                                                placement={"right"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "NEUTRAL"}`}>
                                                        Marcar dron como neutral
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "NEUTRAL")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "NEUTRAL" ? "orange" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "NEUTRAL" ? "orange" : "grey" }}>Neutral</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key={i.toString() + "ENEMY"}
                                                placement={"right"}
                                                overlay={
                                                    <Tooltip id={`tooltip-${i.toString() + "ENEMY"}`}>
                                                        Marcar dron como adversario
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => changeDroneRelation(detectionLog[1][detectionLog[1].length - 1].drone._id, "ENEMY")} className={"optionButton"} style={{ backgroundColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "ENEMY" ? "red" : "grey", borderColor: detectionLog[1][detectionLog[1].length - 1].drone.type == "ENEMY" ? "red" : "grey" }}>Adversario</Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                        :
                                        null
                                }
                            </div>*/}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            );
        }
        return aux;
    }

    async function changeDroneRelation(droneId: any, newType: String) {
        let body = { type: newType };
        await updateDrone(droneId, body);
    }

    return (

        <Accordion style={{ height: "", overflowY: "scroll", marginTop: "20px", marginRight: "20px" }}>

            {Object.keys(websocketStore.activeDetections).length > 0 ? getCards() :
                <Card>
                    <Card.Header>
                        <h5>{t("pages.realtime.without-detections")}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={5} />
                            <Col>
                                <Spinner animation="grow" variant="primary" style={{ width: "100%", paddingTop: "100%" }} />
                            </Col>
                            <Col xs={5} />
                        </Row>
                    </Card.Body>
                </Card>
            }
        </Accordion>
    );
});

export default RealtimeColumn;