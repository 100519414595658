import React, { useContext, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { ZonesDataStoreContext, ZonesStatus } from '../../../stores/zonesdata.store';
import { MapAnimationsStoreContext, FocusStatus } from '../../../stores/mapanimations.store';
import ZonesCreateForm from './zonesCreateForm.component';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserDataStoreContext } from '../../../stores/userdata.store';
import { useTranslation } from 'react-i18next';


const ZonesColumn = observer(() => {

    const [t, i18n] = useTranslation("common");

    const mapanimationsStore = useContext(MapAnimationsStoreContext);
    const zonesDataStore = useContext(ZonesDataStoreContext);
    const userStore = useContext(UserDataStoreContext);

    var zonesItems = [];


    for (var i = 0; i < zonesDataStore.zones.length; i++) {
        let register = zonesDataStore.zones[i];
        let idx = i;
        zonesItems.push(
            <Card style={{ marginTop: "10px" }}>
                <Card.Header style={{ display: "flex" }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()}>
                        {zonesDataStore.zones[i].name}
                    </Accordion.Toggle>
                    <OverlayTrigger
                        key={1}
                        placement="bottom"
                        overlay={
                            <Tooltip id={'showZone'}>
                                {t("pages.zones.column.btn-show-zone-hover")}
                            </Tooltip>
                        }
                    >
                        <Button style={{ marginLeft: "auto" }} onClick={() => mapanimationsStore.focusOnZone(register)}>
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </OverlayTrigger>
                </Card.Header>
                <Accordion.Collapse eventKey={i.toString()}>
                    <Card.Body style={{ padding: "0rem 1.25rem" }}>
                        <Table responsive style={{ textAlign: "left", margin: "20px" }}>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderTop: "none", width: "40%" }}>{t("zone.name")}</td>
                                    <td style={{ borderTop: "none" }}>{zonesDataStore.zones[i].name}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("zone.level")}</td>
                                    <td>{zonesDataStore.zones[i].securityLevel}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("zone.type")}</td>
                                    <td>{zonesDataStore.zones[i].type}</td>
                                </tr>
                                {
                                    zonesDataStore.zones[i].type === "CIRCLE" ? (
                                        <>
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("zone.center")}</td>
                                                <td>
                                                    {zonesDataStore.zones[i].center.lat !== undefined 
                                                        ? parseFloat(zonesDataStore.zones[i].center.lat.toFixed(3)).toString() 
                                                        : zonesDataStore.zones[i].center.lat}, 
                                                    {zonesDataStore.zones[i].center.lon !== undefined 
                                                        ? parseFloat(zonesDataStore.zones[i].center.lon.toFixed(3)).toString() 
                                                        : zonesDataStore.zones[i].center.lon}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "bold" }}>{t("zone.radius")}</td>
                                                <td>
                                                    {zonesDataStore.zones[i].radius !== undefined 
                                                        ? parseFloat(zonesDataStore.zones[i].radius.toFixed(3)).toString() 
                                                        : zonesDataStore.zones[i].radius}m
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>{t("zone.points")}</td>
                                            <td>{zonesDataStore.zones[i].perimeter.length}</td>
                                        </tr>
                                    )
                                }

                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("zone.description")}</td>
                                    <td>{zonesDataStore.zones[i].description}</td>
                                </tr>
                            </tbody>
                        </Table>
                        {
                            userStore.user.role !== "USER" ?
                                zonesDataStore.zones[i].securityLevel != "WARNING" ?
                                    < Row style={{ margin: "10px", justifyContent: "center" }}>
                                        <Button variant={"danger"} onClick={() => { zonesDataStore.removeZone(zonesDataStore.zones[idx]._id); setTimeout(() => zonesDataStore.getZones(), 500) }}>{t("pages.zones.column.btn-remove")}</Button>
                                    </Row>
                                    :
                                    null
                                :
                                null
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card >
        )
    }


    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Card.Body style={{ textAlign: "center", overflowY: "scroll" }}>

                {
                    userStore.user.role !== "USER" ?
                        <ZonesCreateForm />
                        :
                        null
                }


                {zonesDataStore.status == 3 ?
                    <Accordion>
                        {zonesItems}
                    </Accordion>
                    :
                    <Container style={{ marginTop: "100px" }}>
                        <Row>
                            <Col xs={5} />
                            <Col>
                                <Spinner animation="grow" variant="primary" style={{ width: "100%", paddingTop: "100%", overflowY: "hidden" }} />
                            </Col>
                            <Col xs={5} />
                        </Row>
                    </Container>
                }
            </Card.Body>
        </Card>

    );
});

export default ZonesColumn;
/*<div style={{ height: "100%", overflowY: "hidden" }}>
            <ZonesCreateForm />
            {zonesDataStore.status == 3 ?
                <div style={{ maxHeight: "88%", overflowY: "scroll", marginTop: "30px" }}>
                    <Accordion>
                        {zonesItems}
                    </Accordion>
                </div>
                :
                <Container style={{ marginTop: "100px" }}>
                    <Row>
                        <Col xs={5} />
                        <Col>
                            <Spinner animation="grow" variant="primary" style={{ width: "100%", paddingTop: "100%", overflowY: "hidden" }} />
                        </Col>
                        <Col xs={5} />
                    </Row>
                </Container>
            }
        </div>*/