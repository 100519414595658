import axios from "axios";

const API_URL = "/api";
// const API_URL = "https://sendesgate.asdt.eu/api";
// const API_URL = "http://devserver.asdt.eu/api";

export const handleMapboxTokenSubmit = async (mapboxToken: string) => {
  try {
    let token = localStorage.getItem("userToken");
    let options: RequestInit = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        token: mapboxToken,
      }),
    };

    let response = await fetch(API_URL + "/groups/mapbox-token", options);
    if (response.status !== 200) {
      return [false, response.statusText];
    }
    return [true, await response.json()];
  } catch (error) {
    console.error("Error submitting Mapbox token:", error);
    throw error; // Rethrowing the error so the UI can handle it
  }
};

export const requestOTP = async (phoneNumber: string) => {
  let token = localStorage.getItem("userToken");
  try {
    const response = await axios({
      url: `${API_URL}/authenticate/request-otp`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        phoneNumber,
      },
    });
    return response.data; // This will be the message "OTP sent to the provided phone number."
  } catch (error) {
    console.error("Error requesting OTP:", error);
    throw error; // Rethrowing the error so the UI can handle it
  }
};

export const enable2FA = async (phoneNumber: string, otp: string) => {
  let token = localStorage.getItem("userToken");
  try {
    const response = await axios({
      url: `${API_URL}/authenticate/enable-2fa`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        phoneNumber,
        otp,
      },
    });
    return response.data; // This will be the success message from the server
  } catch (error) {
    console.error("Error enabling 2FA:", error);
    throw error;
  }
};

export const disable2FA = async () => {
  let token = localStorage.getItem("userToken");
  try {
    const response = await axios({
      url: `${API_URL}/authenticate/disable-2fa`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // This will be the success message from the server
  } catch (error) {
    console.error("Error disabling 2FA:", error);
    throw error;
  }
};

export async function setInhibitorAutotrack(
  groupId: string,
  inhibId: string,
  body: any
) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  let response = await fetch(
    API_URL +
      "/groups/" +
      groupId +
      "/inhibitors/" +
      inhibId +
      "/autoinhibition2",
    options
  );
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function userLogin(email: String, password: String) {
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  };
  let response = await fetch(API_URL + "/authenticate", options);

  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}
export async function validateLoginOtp(email: String, otp: String) {
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      otp,
    }),
  };
  let response = await fetch(
    API_URL + "/authenticate/validate-otp-login",
    options
  );

  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}
// export async function requestLoginOtp(email: string) {
//   let options: RequestInit = {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       email,
//     }),
//   };
//   let response = await fetch(API_URL + "/authenticate/request-otp-login", options);

//   if (response.status !== 200) {
//     return [false, response.statusText];
//   }
//   return [true, await response.json()];
// }

export async function checkSavedToken(token: String) {
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let response = await fetch(API_URL + "/authenticate/" + token, options);

  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getRegisters(params: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let response = await fetch(API_URL + "/flightlogs" + params, options);

  if (response.status !== 200) {
    return [false, response.statusText];
  }

  return [true, await response.json()];
}

export async function getExcel(params: String) {
  let token = localStorage.getItem("userToken");
  let lang = localStorage.getItem("language") || "en"; // Default to "en"

  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  // If params is empty, initialize with ?lang
  // Get the user's timezone offset in minutes and convert it to hours
  let timezoneOffset = new Date().getTimezoneOffset();

  if (!params) {
    params = `?lang=${lang}&timezoneOffset=${timezoneOffset}`;
  } else {
    params += `lang=${lang}&timezoneOffset=${timezoneOffset}`; // Append lang if params exist
  }

  // Fetch the Excel file
  let response = await fetch(API_URL + "/flightlogs/export" + params, options);

  if (response.status !== 200) {
    return false;
  }

  response.blob().then((blob) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = "Informe_Detecciones_SIGLO.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return true;
}

export async function getZones() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/zones", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getInhibitors() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/inhibitors", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getGroupInhibitors(groupId: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(
    API_URL + "/groups/" + groupId + "/inhibitors",
    options
  );
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getGroupDetectors(groupId: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(
    API_URL + "/groups/" + groupId + "/detectors",
    options
  );
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getUser() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/users", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function changePassword(body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  let response = await fetch(API_URL + "/users", options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function createUser(body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      name: body.name,
      email: body.email,
      password: body.password,
      group: body.group,
      role: body.role,
      identification: body.identification,
      phoneNumber: body.phoneNumber,
    }),
  };
  let response = await fetch(API_URL + "/users", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function deleteUser(userId: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/users/" + userId, options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getGroup() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/groups", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getGroupChildren() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/groups/all", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function createZone(parentZoneId: String, body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  let response = await fetch(API_URL + "/zones/" + parentZoneId, options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function setInhibitorStatus(
  pins: any,
  groupId: string,
  inhibId: string
) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(pins),
  };

  let response = await fetch(
    API_URL + "/groups/" + groupId + "/inhibitors/" + inhibId,
    options
  );
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getDrones(page: number, query: string) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(
    API_URL + "/drones/?pageNumber=" + page + query,
    options
  );
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function createAuthorization(body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  let response = await fetch(API_URL + "/authorizations", options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function getAuthorizations() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/authorizations", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function updateDrone(droneId: String, body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  let response = await fetch(API_URL + "/drones/" + droneId, options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function removeZone(zoneId: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/zones/" + zoneId, options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function getModelImage(modelId: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/models/" + modelId, options);
  if (response.status !== 200) {
    return [false, response.url];
  }
  return [true, response.url];
}

export async function createDrone(body: any) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  let response = await fetch(API_URL + "/drones", options);
  if (response.status !== 200) {
    return false;
  }
  return true;
}

export async function getModels() {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(API_URL + "/models", options);
  if (response.status !== 200) {
    return [false, response.statusText];
  }
  return [true, await response.json()];
}

export async function getPDF(
  params: String,
  sn: String,
  timeZoneOffset: number
) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(
    API_URL +
      "/flightlogs/" +
      params +
      "/pdf" +
      "?timezoneOffset=" +
      timeZoneOffset +
      "&lang=" +
      (localStorage.getItem("language") || "en"),
    options
  );

  if (response.status !== 200) {
    return false;
  }

  response.blob().then((blob) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    var now = new Date(Date.now());
    a.download =
      "Informe_" +
      sn +
      "_" +
      ("0" + now.getDate()).slice(-2) +
      "-" +
      ("0" + (now.getMonth() + 1)).slice(-2) +
      "-" +
      now.getFullYear() +
      ".pdf";
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return true;
}

export async function getKML(params: String, sn: String) {
  let token = localStorage.getItem("userToken");
  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let response = await fetch(
    API_URL + "/flightlogs/" + params + "/kml",
    options
  );

  if (response.status !== 200) {
    return false;
  }

  response.blob().then((blob) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    var now = new Date(Date.now());
    a.download =
      "Informe_" +
      sn +
      "_" +
      ("0" + now.getDate()).slice(-2) +
      "-" +
      ("0" + (now.getMonth() + 1)).slice(-2) +
      "-" +
      now.getFullYear() +
      ".kml";
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return true;
}
